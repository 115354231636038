<template>
    <section class="other-bill">
        <div style="text-align: right">
            <el-link type="primary" :underline="false">一键催收</el-link>
            <span style="margin:0 10px"/>
            <el-link type="primary" :underline="false">添加房租账单</el-link>
        </div>
        <div class="table-title">待处理账单</div>
        <r-e-table ref="tableUNRef" :data="[]" :columns="tableColumns" :height="300">
            <el-table-column prop="name" label="账单名称" :show-overflow-tooltip="true"/>
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{  }">
                    <div class="table-tools">

                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <div class="table-title">已处理账单</div>
        <r-e-table ref="tableRef" :data="[]" :columns="tableColumns" :height="340">
            <el-table-column prop="comment" label="账单备注" :show-overflow-tooltip="true"/>
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{  }">
                    <div class="table-tools">

                    </div>
                </template>
            </el-table-column>
        </r-e-table>
    </section>
</template>

<script>
    import {billColumns} from "../../data";

    export default {
        name: "other-bill",
        data() {
            return {
                tableColumns: billColumns,
            };
        },
        components: {
        },
        async created() {
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>