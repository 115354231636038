<template>
    <section></section>
</template>

<script>
    export default {
        name: "detail"
    }
</script>

<style scoped>

</style>