<template>
    <section class="auction">
        <div>
            <div style="text-align: center;font-size: 20px;margin:10px 0;">公司名称 : {{sysShopLeasor.subCompanies?sysShopLeasor.subCompanies:''}}</div>
            <div style="text-align: center;font-size: 20px;margin:10px 0;">
                <span style="padding: 0 10px;">姓名 : {{sysShopLeasor.name?sysShopLeasor.name:''}}</span>
                <span style="padding: 0 10px;">手机号 : {{sysShopLeasor.phone?sysShopLeasor.phone:''}}</span>
                <span style="padding: 0 10px;">身份证 : {{sysShopLeasor.idcard?sysShopLeasor.idcard:''}}</span>
                <span style="padding: 0 10px;">竞拍时间: {{bidDate?bidDate:''}}</span>
            </div>
        </div>

        <div>
            <div style="text-align: right;margin: 5px 10px;">
                <el-link type="primary" :underline="false" @click="addAuction">添加竞拍</el-link>
            </div>
            <div class="flex">
                <el-button :type="auctionType==='newest'?'primary':''" @click="switchRecord('newest')"
                           style="font-size: 16px; padding: 8px 15px;">
                    当前竞拍
                </el-button>
                <el-button :type="auctionType==='history'?'primary':''" @click="switchRecord('history')"
                           style="font-size: 16px; padding: 8px 15px;">
                    历史竞拍
                </el-button>
            </div>
            <r-e-table v-if="auctionType==='newest'" ref="tableRef" :data="tableData" :columns="tableColumns"
                       :height="650" :show-pagination="false">
                <template slot="empty">
                    <el-empty/>
                </template>
            </r-e-table>
            <r-e-table v-if="auctionType==='history'" ref="tableRef" :data="tableLishiData" :columns="tableLishiColumns"
                       :height="650" :show-pagination="false">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="100">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" @click="look(row)">查看</span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
        <layer-add-auction ref="layerAddAuction" :shopApartmentUuid="currentShops.uuid"/>

        <layer-look-auction ref="layerLookAuction"/>
    </section>
</template>

<script>
    import {auctionColumns, auctionLishiColumns} from "../../data";
    import {getApartmentBidding, getApartmentBiddingList} from '@/api/shops-lease';
    import {timeFormat} from "@custom/index";
    export default {
        name: "auction",
        data() {
            return {
                tableColumns: auctionColumns,
                tableLishiColumns: auctionLishiColumns,
                tableData: [],
                auctionType: "newest",
                tableLishiData: [],
                sysShopLeasor:{},
                bidDate: '',
            }
        },
        props: {
            currentShops: {
                type: Object,
                default: () => {
                }
            },
        },
        components: {
            layerAddAuction: () => import("./components/layer-add-auction"),
            layerLookAuction: () => import("./components/layer-look-auction")
        },
        created() {
            this.getApartmentBidding();
        },
        methods: {
            handleSearch() {
                // 搜索
                //this.$refs['tableRef'].getTableData();
                this.getApartmentBidding();
            },
            addAuction() {
                this.$refs["layerAddAuction"].openDialog();
            },
            switchRecord(auctionType) {
                this.auctionType = auctionType;
                if (auctionType === 'newest') {
                    this.getApartmentBidding();
                } else {
                    this.getApartmentBiddingList();
                }
            },
            look(data) {
                this.$refs['layerLookAuction'].openDialog(data);
            },
            async getApartmentBidding() {
                let res = await getApartmentBidding({uuid: this.currentShops.uuid});
                if(res.info){
                    let {info:{shopContractBiddingInformationInfoList: list, bidInfo}} = res;
                    if(bidInfo){
                        let {sysShopLeasor,bidDate} = bidInfo;
                        if(bidDate) this.bidDate = timeFormat(new Date(bidDate), 'yyyy-MM-dd HH:mm:ss');
                        if(sysShopLeasor) this.sysShopLeasor = sysShopLeasor;
                    }
                    this.tableData = list;
                }
            },
            async getApartmentBiddingList() {
                let res = await getApartmentBiddingList({uuid: this.currentShops.uuid});
                let {list}=res;
                this.tableLishiData=list;
            }
        }
    }
</script>

<style scoped>

</style>