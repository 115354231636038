import {timeFormat, numberFormat} from "@custom/index";

// 商铺卡片，信息展示内容
export const shopsOptions = [
    {
        label: "承租人",
        name: "leasor"
    },
    {
        label: "座落位置",
        name: "shopAddress"
    },
    {
        label: "面积",
        name: "area",
        unit: "㎡",
    },
];

// 商铺的设备
export const shopsDevice = [
    {
        label: "水表",
        name: "water",
    },
    {
        label: "电表",
        name: "elect",
    },
    {
        label: "气表",
        name: "gas",
    },
    {
        label: "智能锁",
        name: "intelLock",
    }
];

// 商铺tabs
export const shopsTabOptions = [
    {
        label: "商铺竞拍",
        name: "auction"
    },
    {
        label: "关联商铺",
        name: "subsidiary"
    },
    {
        label: "商铺合同",
        name: "contract"
    },
    {
        label: "商铺账单",
        name: "bill"
    },
    /*{
        label: "杂费账单",
        name: "other-bill"
    },*/
    {
        label: "商铺详情",
        name: "detail"
    },
];
// 竞拍列表
export const auctionColumns = [
    {
        label: '序号',
        type: 'index',
        width: 80
    },
    {
        label: '状态',
        render: (h, {row}) => {
            const {bidStatus} = row;
            let type = bidStatus === 2 ? 'danger' : 'info';
            return h("el-tag", {props: {type: type, size: "small"}}, bidStatus === 2 ? "成交" : "出局");
        }
    },
    {
        prop: 'biddingNumber',
        label: '竞买号',
    },
    {
        label: '价格',
        render: (h, {row}) => {
            const {bidPrice} = row;
            return h("span", numberFormat(bidPrice / 100));
        }
    },
    {
        prop: 'bidDate',
        label: '竞拍时间',
        render: (h, {row}) => {
            const {bidDate} = row;
            return h("span", timeFormat(new Date(bidDate), 'yyyy-MM-dd HH:mm:ss'));
        }
    },
];
export const auctionLishiColumns = [
    {
        label: '序号',
        type: 'index',
        width: 80,
    },
    {
        prop: "biddingProject",
        label: '竞拍项目',
    },
    {
        prop: 'startingPrice',
        label: '起拍价',
    },
    {
        prop: "finalPrice",
        label: '成交价格',
    },
    {
        prop: "biddingTime",
        label: '竞拍时间',
    },
    {
        prop: "biddingLessee",
        label: '竞拍客户',
    },
    {
        prop: 'biddingState',
        label: '竞拍状态',
    },
];
// 合同列表
export const contractColumns = [
    {
        prop: "leasor.leasorName",
        label: "承租人",
    },
    {
        label: "合同周期",
        width: 200,
        render: (h, {row}) => {
            const {startDate, endDate} = row;
            return timeFormat(new Date(startDate)) + " 至 " + timeFormat(new Date(endDate));
        }
    },
    {
        prop: "apartment.apartmentName",
        label: "座落",
    },
    {
        label: "合同状态",
        render: (h, {row}) => {
            const {contractStatus: code, contractStatusName: codeName} = row;
            let type = "";
            switch (code) {
                case 2:
                    type = "success";
                    break;
                case 0:
                    type = "info";
                    break;
                case 1:
                    type = "warning";
                    break;
                case 4:
                    type = "";
                    break;
                case 3:
                    type = "danger";
                    break;
            }
            return h(
                "el-tag",
                {
                    props: {
                        type: type,
                        size: "small"
                    }
                },
                codeName
            );
        }
    },
    {
        label: "水电押金",
        width: 100,
        render: (h, {row}) => {
            const {payBill: {hydropowerDeposit}} = row;
            return numberFormat(hydropowerDeposit / 100);
        }
    },
    {
        label: "消费押金",
        width: 100,
        render: (h, {row}) => {
            const {payBill: {fireDeposit}} = row;
            return numberFormat(fireDeposit / 100);
        }
    },
    {
        label: "履约押金",
        width: 100,
        render: (h, {row}) => {
            const {payBill: {rentalDeposit}} = row;
            return numberFormat(rentalDeposit / 100);
        }
    }
];
// 附属商铺列表
export const subsidiaryColumns = [
    {
        prop: "name",
        label: "商铺名称",
    },
    {
        prop: "apartmentNo",
        label: "商铺编号",
    },
    {
        prop: "houseStatus",
        label: "店铺状态"
    },
    {
        label: "建筑面积",
        render: (h, {row}) => {
            const {outArea} = row;
            return outArea+"m³";
        }
    },
    {
        label: "使用面积",
        render: (h, {row}) => {
            const {useArea} = row;
            return useArea+"m³";
        }
    },
];

// 账单列表
export const billColumns = [
    {
        label: "账单周期",
        width: 200,
        render: (h, {row}) => {
            const {startDate, endDate} = row;
            return timeFormat(new Date(startDate)) + " 至 " + timeFormat(new Date(endDate));
        }
    },
    {
        prop: "apartment.name",
        label: "房源地址",
        showOverflowTooltip: true
    },
    {
        prop: "paid",
        label: "账单状态",
        width: 80,
        render: (h, {row}) => {
            const {statusCodeName, statusCode} = row;
            return h(
                "el-tag",
                {
                    props: {
                        type: statusCode === 600602 ? "success" : statusCode === 600601 ? "warning" : 'info',
                        size: "small"
                    }
                },
                statusCodeName
            );
        }
    },
    {
        prop: "typeName",
        label: "账单类型",
        width: 120,
    },
    {
        label: "账单金额",
        width: 120,
        render: (h, {row}) => {
            return numberFormat(row.receivableAmount / 100);
        }
    },
    {
        prop: "paidDate",
        label: "支付时间",
        width: 180,
        render: (h, {row}) => {
            let {paidTime} = row;
            paidTime = paidTime ? timeFormat(new Date(paidTime), "yyyy-MM-dd HH:mm:ss") : "";
            return h("span", paidTime);
        }
    },
    /*{
        prop: "startDate",
        label: "应收款日期",
        width: 95,
        render: (h, {row}) => {
            return timeFormat(new Date(row.startDate));
        }
    }*/
];

//租金信息列表
export function rentInformationColumns(that) {
    return [];
}

// 保证金列表
export const depositColumns = [
    {
        type: "selection",
        width: 80,
        selectable: (row, index) => {
            return row.statusCode !== 600602;
        },
    },
    {
        prop: "shopContract.contractPcNumber",
        label: "合同编号"
    },
    {
        prop: "ShopCommunityName",
        label: "商圈",
    },
    {
        prop: "ShopApartmentName",
        label: "商铺编号",
    },
    {
        prop: "shopLeasor.name",
        label: "承租人",
    },
    {
        prop: "typeName",
        label: "保证金类型",
    },
    {
        prop: "receivableAmount",
        label: "保证金额",
        render: (h, {row}) => {
            const {receivableAmount = 0} = row;
            return numberFormat(receivableAmount / 100);
        }
    },
    {
        prop: "statusCodeName",
        label: "付款状态",
    },
    {
        prop: "actualAmount",
        label: "付款金额",
        render: (h, {row}) => {
            const {actualAmount = 0} = row;
            return numberFormat(actualAmount / 100);
        }
    },
    {
        prop: "paymentChannel",
        label: "支付方式",
    }
];