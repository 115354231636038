<template>
    <r-e-drawer class="drawer-shops-info" :title="drawerTitle" :visible.sync="drawerVisible" :size="1400" modal
                wrapper-closable drawer-wrapper>
        <div slot="title" class="drawer-header flex align-center">
            <div class="drawer-title">{{ drawerTitle }}</div>
            <div class="room-status">{{ roomStatus }}</div>
        </div>
        <div class="drawer-container">
            <div class="container-header flex align-center">
                <div class="item text-center" :class="{ 'is-active-tab': item.name === activeTab }"
                     v-for="item in shopsTabOptions" :key="item.name" @click="activeTab = item.name">
                    {{ item.label }}
                </div>
            </div>
            <div class="container-content hidden-scrollbar">
                <component :is="activeTab" :current-shops="currentHouse"/>
            </div>
        </div>
    </r-e-drawer>
</template>

<script>
    import {shopsTabOptions} from "../data";
    import {auction, contract, bill, otherBill, detail, subsidiary} from "./part";

    export default {
        props: {
            shopsStatus: {
                type: Array,
                default: () => []
            },
        },
        name: "drawer-shops-info",
        data() {
            return {
                shopsTabOptions,
                activeTab: "auction",
                drawerVisible: false,
                drawerTitle: "房源",
                roomStatus: "未出租",
                currentHouse: {}
            };
        },
        components: {auction, contract, bill, otherBill, detail, subsidiary},
        methods: {
            openDrawer(data) {
                const {address, roomStatus} = data;
                this.drawerTitle = address;
                this.roomStatus = this.createRoomStatus(roomStatus);
                this.drawerVisible = true;
                this.currentHouse = data
            },
            createRoomStatus(status) {
                // 房间状态
                if (!this.shopsStatus.map(v => v.code).includes(status)) return "--";
                const {codeName} = this.shopsStatus.find(v => v.code === status);
                return codeName;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .drawer-shops-info {
        .drawer-header {
            .room-status {
                color: #1F7CF6;
                font-size: rem(14px);
                border-radius: 15px;
                padding: VH(3px) VW(10px);
                background: #CEE7FF;
                margin-left: VW(10px);
            }
        }

        .drawer-container {
            margin-left: VW(5px);

            .container-header {
                width: fit-content;
                border-radius: 4px;
                box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);

                .item {
                    padding: VH(15px) VW(30px);
                    color: #666;
                    font-size: rem(16px);
                    cursor: pointer;
                    border-radius: 4px;
                    margin-right: VW(5px);

                    &:last-child {
                        margin-right: 0;
                    }

                    &.is-active-tab,
                    &:hover {
                        color: #fff;
                        background: linear-gradient(80deg, #A8D4FF 0%, #2386F6 100%);
                        box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
                    }
                }
            }

            .container-content {
                height: VH(870px);
                margin-top: VH(20px);
            }
        }

        /deep/ .container-content {
            .table-title {
                position: relative;
                padding-left: 10px;
                margin: 20px 0;

                &::before {
                    width: 5px;
                    height: 100%;
                    content: '';
                    background-color: #F5A623;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
</style>
