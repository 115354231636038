<template>
    <section class="subsidiary">
        <!--<div style="text-align: right;margin-bottom: 20px">
            <el-button type="primary" style="font-size: 16px;padding: 8px 15px;font-weight: 600"
                       @click="addSubsidiaryShops">添加附属商铺
            </el-button>
        </div>-->
        <div>
            <r-e-table ref="tableRef" :dataRequest="apartmentset" :columns="tableColumns" :height="750">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="100">
                    <template slot-scope="{ }">
                        <div class="table-tools">
                            <span class="table-btn">查看详情</span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
        <layer-add-subsidiary-shops ref="layerAddSubsidiaryShops"/>
    </section>
</template>

<script>
    import {subsidiaryColumns} from "../../data";
    import {apartmentset} from "@/api/shops-lease";
    export default {
        name: "subsidiary",
        props: {
            currentShops: {
                type: Object,
                default: () => {}
            },
        },
        data() {
            return {
                tableColumns: subsidiaryColumns,
            }
        },
        components: {
            layerAddSubsidiaryShops: () => import('./components/layer-add-subsidiary-shops')
        },
        methods: {
            /*addSubsidiaryShops() {
                this.$refs["layerAddSubsidiaryShops"].openDialog();
            }*/
            apartmentset(params){
                const {uuid} = this.currentShops;
                return apartmentset({...params,uuid});
            }
        }
    }
</script>

<style scoped>

</style>