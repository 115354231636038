<template>
    <section class="bill">
        <!--<div style="text-align: right">
            <el-link type="primary" :underline="false">一键催收</el-link>
            <span style="margin:0 10px"/>
            <el-link type="primary" :underline="false">添加房租账单</el-link>
        </div>-->
        <div class="table-title">待处理账单</div>
        <r-e-table ref="tableUNRef" :dataRequest="() => ShopsPaybill(600601)" :columns="tableColumns" :height="300"
                   :showPagination="false">
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="row.statusCode!==600602" @click="heXiao(row)">核销</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <div class="table-title">已处理账单</div>
        <r-e-table ref="tableRef" :dataRequest="() => ShopsPaybill(600602)" :columns="tableColumns" :height="340"
                   :showPagination="false">
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{  }">
                    <div class="table-tools"></div>
                </template>
            </el-table-column>
        </r-e-table>

        <layer-bill-hexiao ref="layerBillHexiao" @get-data="getShopsPaybill"/>
    </section>
</template>

<script>
    import {billColumns} from "../../data";
    import {apartmentPaybill} from "@/api/shops-lease"

    export default {
        name: "bill",
        props: {
            currentShops: {
                type: Object,
                default: () => {}
            },
        },
        data() {
            return {
                tableColumns: billColumns,
            };
        },
        components: {
            layerBillHexiao:()=>import("./components/layer-bill-hexiao")
        },
        async created() {
        },
        methods: {
            ShopsPaybill(status = 0) {
                const {uuid} = this.currentShops;
                return apartmentPaybill({uuid, status});
            },

            heXiao(data) {
                let that = this;
                that.$refs["layerBillHexiao"].openDialog({bills: [data]});
            },

            getShopsPaybill(){
                this.$refs['tableUNRef'].getTableData();
                this.$refs['tableRef'].getTableData();
            }
        }
    }
</script>

<style scoped>

</style>